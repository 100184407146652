import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import PageHero from "../components/common/page-hero"

const MarketingCampaignPage = () => (
  <Layout>
    <Seo title="Marketing Campaign" />
    <PageHero title="Marketing Campaign" />

    <div className="bg-gray-100">
      <div className="w-11/12 max-w-screen-lg mx-auto pt-16 pb-20">
        <p className="text-lg mb-6">HireLive has extensive marketing efforts and partnerships with the major job boards and adds more than 600,000 resumes to our proprietary database each year. HireLive currently has more than 6 million resumes in our ATS. With such an extensive database of current, active and passive candidates, HireLive’s Email and Text Message Campaigns are very effective in directing a solid volume of quality candidates directly to you and your internal recruiting efforts and events. With the success of our marketing campaigns, this service has become our fastest growing amongst all services.</p>
        <h2 className="text-2xl mb-8">Here is how a HireLive “Marketing Campaign” Works:</h2>
        <p className="text-lg mb-6"><b>Step 1:</b> Call us with as little as 1 day prior to when you would like your campaign to be sent out and how many emails or text messages you would like your campaign to be sent to.</p>
        <p className="text-lg mb-6"><b>Step 2:</b> Fill out our Marketing Campaign Job Order Form which gives us the city, state, day, time, sourcing criteria, message and more for your campaign. This will also let us know if you would like the campaign to direct candidates to a link, job posting, website, an event or just have the resumes forward to you of interested candidates.</p>
        <p className="text-lg mb-6"><b>Step 3:</b> Sign and return the agreement for your campaign.</p>
        <p className="text-lg mb-6"><b>Step 4:</b> HireLive will source and pull data from our own Proprietary database, which has been developed over the past 12 years from Career Fairs, Open Houses, Contingency searches and more than 100,000 online jobs posted.</p>
        <p className="text-lg mb-6"><b>Step 5:</b> HireLive will deliver your message to either a candidate&#39;s phone screen via text or inbox via email.</p>
        <h2 className="text-2xl mb-8">Benefits of a HireLive “Marketing Campaign”</h2>
        <ul className="mb-6 list-inside list-disc">
          <li className="text-lg ml-3 mb-6">This is an extremely inexpensive way to get your message to current active and passive candidates.</li>
          <li className="text-lg ml-3 mb-6">The ability to build a pipeline quickly in any market across the US.</li>
          <li className="text-lg ml-3 mb-6">Flat fee, you can make as many hires as you like from the campaign.</li>
          <li className="text-lg ml-3 mb-6">Not having to worry about getting your email address or domain put on blacklists if you were doing your own large email campaigns.</li>
        </ul>
      </div>
    </div>
  </Layout>
)

export default MarketingCampaignPage